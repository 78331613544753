<template>
	<div>
		<el-dialog
      width="1000px"
			direction="rtl"
			title="评价详情"
			:before-close="close"
			:visible.sync="drawerVisible"
			:destroy-on-close="true"
			custom-class="box-drawer"
		>
			<div class="content">
				<p>商品图：<img class="img" :src="currentData.cover? currentData.cover : defaultAvatarUrl" alt=""></p>
				<p>商品名称： {{ currentData.goodsName }}</p>
				<p>规格： {{ currentData.skuName }}</p>
				<p>已购数量： {{ currentData.goodsNum }} 价格：￥{{ currentData.priceTotal }}</p>
				<p>用户信息：
					<img style="width: 60px;height: 60px;vertical-align: middle;border-radius: 50%;" :src="currentData.userAvatarUrl? currentData.userAvatarUrl : defaultAvatarUrl" alt="">
					<span style="margin-left: 15px;">{{ currentData.userNickName }}</span>
					<span style="margin-left: 15px;">{{ currentData.isAnonymous == 1? '不公开' : '公开' }}</span>
					<span style="margin-left: 15px;">{{ currentData.createTime}}</span>
				</p>
				<p>点赞数: {{ currentData.likeTimes }}</p>
				<p>商品评价：
					<el-rate v-model="currentData.rating" :disabled="true"></el-rate>
          <span>评论: {{ currentData.commentMsg }}</span>
					<el-row>
						<el-col v-if="!currentData.mediaList.length"> <el-empty :image-size="80" /> </el-col>
						<el-col
							:span="6"
							style="cursor: pointer;"
							v-for="(item, index) in currentData.mediaList"
							:key="index"
							>
							<el-image @click="previewItem(item, index)" v-if="item.type == 1" style="width: 100px; height: 100px;" fit="contain" :src="item.url"/>
							<div @click="previewItem(item, index)" class="videoMiniBox" v-else-if="item.type == 2">
								<video
									class="videoMini"
									:src="item.url" controls="controls"
									style="width: 100px;height: 100px;"
								>您的浏览器不支持视频播放</video>
								<el-icon style="text-align: center;line-height: 100px;" class="el-icon-caret-right"></el-icon>
							</div>
							<span v-else> 数据错误！ </span>
						</el-col>
					</el-row>
				</p>
				<p>商家回复:
					<el-input :disabled="currentData.isReply !=0" style="margin-top: 10px;" :rows="4" type="textarea" placeholder="请输入内容" v-model="currentData.replyMsg" maxlength="200" show-word-limit></el-input>
				</p>
				<p style="overflow: hidden;" v-if="currentData.isReply ==0">
					<el-button style="float: right;" type="primary" @click="submit">确定</el-button>
				</p>
			</div>
		</el-dialog>
		<el-dialog title="查看" append-to-body style="z-index: 5001;" class="my-dialog" :visible.sync="isVisible" width="800px">
			<el-carousel v-if="isVisible" height="430px" :autoplay="false" trigger="click" :initial-index="initialIndex">
				<el-carousel-item v-for="(item, index) in currentData.mediaList" :key="index">
					<el-image v-if="item.type == 1" :src="item.url" fit="contain" style="width: 760px;height: 430px;"  />
					<video  v-else-if="item.type == 2" :src="item.url" controls="controls" style="width: 760px;height: 430px;" >
						您的浏览器不支持视频播放
					</video>
					<el-empty v-else :image-size="180" description="数据格式有误！" />
				</el-carousel-item>
			</el-carousel>
			<template #footer>
				<el-button type="primary" @click="isVisible = false">确定</el-button>
			</template>
		</el-dialog>
		<!-- 记录预览 -->
	</div>
</template>
<script>
import defaultAvatarUrl from '@/assets/defaultAvatarUrl.png';
const URL = {
	replyComment: 'back/suppOrderComment/replyComment',
}
export default {
	name:'recordDetail',
	components:{},
	props: {
		drawerVisible:{
			type: Boolean,
			default: false
		},
		currentData:{
			type:Object,
			default: () => {}
		}
	},
	data() {
		return {
			list: [
				{ "url": "http://imgs.cunzhiliao.com/tmp/images/2023/05/04/20230504102803_n9LLzt2a.jpeg", "type": "1" },
				{ "url": "http://imgs.cunzhiliao.com/tmp/images/2023/05/04/20230504102839_IKwavO9C.jpeg", "type": "1" },
				{ "url": "http://imgs.cunzhiliao.com/tmp/images/2023/05/04/20230504102902_h1YTFBtO.jpeg", "type": "1" },
				{ "url": "http://1306840708.vod2.myqcloud.com/646dc027vodcq1306840708/5a7eb4d5243791581939924013/62CKvTD2HXwA.mov", "type": "2" }
			],
			initialIndex: 0,
			isVisible: false,
			defaultAvatarUrl
		}
	},
	computed: {

	},
	mounted() {
		console.log(this.currentData, 'currentData')
	},
	methods:{
		submit () {
			let that = this
			if (!this.currentData.replyMsg) {
				this.$message.warning('请输入商家回复后重试');
				return
			}
			let data = {
				orderCommentId: this.currentData.id,
				replyMsg: this.currentData.replyMsg,
			}

			that.axios.get(URL.replyComment, {params: data}).then((res) => {
				console.log('11545')
				that.$message.success('回复成功');
				that.$emit('close', '')
			})
		},
		previewItem(item, index) {
			if (!item.type) {
				this.$message.warning('数据格式有误，无法预览！');
				return;
			}
			this.initialIndex = index;
			this.isVisible = true;
		},
		close (e) {
			this.currentData = {}
			this.$emit('close', e ?? '')
		},
	}
}
</script>
<style lang="less" scoped>
.box-drawer{
	.content {
		margin:  20px;
		p {
			line-height: 23px;
			padding-left: 20px;
			padding-right: 10px;
			font-size: 14px;
			color: #606266;
		}
		.img {
			width: 120px;
			height: 120px;
			vertical-align: middle;
			margin-right: 20px;
		}
		.el-row {
			padding: 10px;
			margin: 10px 0;
			background: #d7d7d7;
			border-radius: 7px;

			.el-col {
				p {
					line-height: 25px;
				}
			}

			.gridMember {
				display: flex;
				justify-content: space-between;

				.inline-icon {
					margin-left: 7px;
					text-align: end;

					img {
						width: 20px;
						height: 20px;
						vertical-align: middle;
					}
				}
			}

			.videoMiniBox {
				position: relative;

				.videoMini {
					background: black;
					filter: brightness(0.5);

					&::-webkit-media-controls-enclosure {
						display: none;
					}
				}

				i {
					position: absolute;
					top: 0;
					left: 0;
					width: 100px;
					height: 100px;
					color: white;
					font-size: 30px;
					font-weight: bold;
				}
			}

			.previewBox {
				width: 760px;
				height: 430px;
			}
		}
	}
}
</style>
