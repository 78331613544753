<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
	<div class="pages" v-loading="loading">

	  <table-height-auto>
		  <template slot="before">
		    <el-form :inline="true" class="demo-form-inline">
		  	<el-form-item label="用户账号">
		  		<el-input v-model="searchData.userPhone" clearable placeholder="请输入"></el-input>
		  	</el-form-item>
		  	<el-form-item label="商品名称">
		  		<el-input v-model="searchData.goodsName" clearable placeholder="请输入"></el-input>
		  	</el-form-item>
		  	<el-form-item label="评论状态">
		  	  	<el-select v-model="searchData.isDelete" placeholder="请选择" style="width: 160px">
		  			<el-option
		  				v-for="item in serviceType"
		  				:disabled="item.disabled"
		  				:key="item.id"
		  				:label="item.name"
		  				:value="item.id">
		  			</el-option>
		  	  	</el-select>
		  	</el-form-item>
		  	<el-form-item>
		  	  <el-button type="primary" @click="search">查询</el-button>
		  	  <el-button type="primary" @click="rese">重置</el-button>
		  	</el-form-item>
		    </el-form>
		  </template>

		  <template v-slot:table="row">
		  	<el-table v-if="row.height" :height="row.height-40+'px'" :data="tableData" style="width: 100%;">
		  		<el-table-column label="序号" width="100">
		  			<template slot-scope="scope">
		  				{{ scope.$index + 1 + (currentPage - 1) * pageSize }}
		  			</template>
		  		</el-table-column>
		  		<el-table-column prop="commentMsg" label="评价内容" show-overflow-tooltip width="180" />
		  		<el-table-column prop="createTime" label="评价时间" />
		  		<el-table-column prop="likeTimes" label="点赞量" width="100" />
		  		<el-table-column prop="replyMsg" label="评论用户" width="120">
		  			<template #default="scope">
		  				<div>{{ scope.row.userNickName }}</div>
		  				<div>{{ scope.row.userPhone }}</div>
		  			</template>
		  		</el-table-column>
		  		<el-table-column prop="goodsName" label="评价商品" />
		  		<el-table-column prop="replyMsg" label="商品规格" width="200">
		  			<template #default="scope">
              <div>{{ scope.row.skuName }}</div>
              <div>¥{{ scope.row.priceTotal }}</div>
		  			</template>
		  		</el-table-column>
		  		<el-table-column prop="status" label="评价状态" width="100">
		  			<template #default="scope">
		  				<div>{{ scope.row.isDelete == 1? '正常' : '用户删除' }}</div>
		  			</template>
		  		</el-table-column>
		  		<el-table-column prop="date" label="操作" width="160">
		  			<template slot-scope="scope">
		      		<el-button size="mini" v-if="scope.row.isDelete == 1"  type="primary" @click="addTypes(scope.row)">{{scope.row.isReply == 0? '回复' : '查看回复'}}</el-button>
		  			</template>
		  		</el-table-column>
		  	</el-table>
		  </template>

		  <template slot="after">
		    <div class="paging-row">
		  	  <el-pagination
		  	    v-if="totalListNum"
		  	    @size-change="handleSizeChange"
		  	    @current-change="handleCurrentChange"
		  	    :current-page="currentPage"
		  	    :page-sizes="[5, 10, 15, 20]"
		  	    :page-size="pageSize"
		  	    layout="total, sizes, prev, pager, next, jumper"
		  	    :total="totalListNum">
		  	  </el-pagination>
		      </div>
		  </template>
	  </table-height-auto>
		<comment-detail :drawerVisible="typeVisble" v-if="isTypeVisble" :currentData="currentData" @close="closeTypeVisble"></comment-detail >
	</div>
</template>

<script>
	import { disposeSearchData } from '@/assets/js/utils'
	import commentDetail from './components/commentDetail.vue'
	import defaultAvatarUrl from '@/assets/defaultAvatarUrl.png';
	const URL = {
		list: 'back/suppOrderComment/orderCommentPage',
	}
	export default {
	  name: 'comment',
	  components: {
		commentDetail
	  },
	  data(){
		return {
			typeVisble: false,
			isTypeVisble: false,
			currentData: {},
			loading: false,
			searchData:{
				userPhone: '',
				goodsName: '',
				isDelete: '',
			},
			tableData: [],
			serviceType: [
				{ id : '1', name: '正常' },
				{ id : '2', name: '用户删除' },
			],
			currentPage: 1,
			totalListNum: 0,
			pageSize: 10,
			defaultAvatarUrl
		}
	  },
	  created(){
		this.getList()
	  },
	  methods:{
		rese () {
			this.searchData = { userPhone: '', goodsName: '', isDelete: '', },
			this.getList()
		},

		closeTypeVisble() {
			this.typeVisble = false
			this.$nextTick( () => {
				this.isTypeVisble = false
			})
			this.getList()
		},

		addTypes(row) {
			let data = JSON.parse(JSON.stringify(row))
			let img = data.picFileUrl ? JSON.parse(data.picFileUrl) : []
			let mediaList = []
			if (img.length > 0) {
				for (const v of img.values()) {
					mediaList.push( {url: v, type: 1})
				}
			}
			if (data.videoFileUrl) {
        let videoArr = JSON.parse(data.videoFileUrl)
        for (let i = 0; i < videoArr.length; i++) {
          mediaList.push({url: videoArr[i], type: 2})
        }
			}
			data.mediaList = mediaList
			console.log(data, 'data')
			console.log(img, 'img')
			console.log(mediaList, 'mediaList')
			console.log(data, 'data')
			this.currentData = data
			this.isTypeVisble = true
			this.$nextTick( () => {
				this.typeVisble = true
			})
		},
		jointSearchData(){
		  let data = {
			pageNo:this.currentPage,
			pageSize:this.pageSize,
      sortType:0,
		  };
		  data = Object.assign(data,this.searchData);
		  disposeSearchData(data);
		  return data;
		},
		search(){
		  this.currentPage = 1;
		  this.getList()
		},
		getList(){
		  	return new Promise((resolve, reject) => {
				let data = this.jointSearchData();
				this.loading = true;
				this.axios.post(URL.list, data).then(res=>{
					this.totalListNum = parseInt(res.data.total);
					this.tableData = res.data.records;
				}).catch(res=>{
					this.tableData = [];
				}).finally(res=>{
					this.loading = false;
				})
			})
		},
		handleSizeChange(val,type){
		  this.pageSize = val;
		  this.currentPage = 1;
		  this.getList()
		},
		handleCurrentChange(val,type){
		  this.currentPage = val;
		  this.getList();
		},
	  },
	}
  </script>

  <style lang="less">
	.form-item{
	  .el-input{
		  max-width: 300px;
	  }
	}
	.avatar {
		width: 60px;
		height: 60px;
		border-radius: 10px;
	}
  </style>
